import React from "react"
import { Typography } from "@material-ui/core"
import MainLayout from "../components/Layout/MainLayout"
import Section from "../components/Layout/Section"
import SEO from "../components/Seo/"

const NotFoundPage = ({ location }) => (
  <MainLayout location={location}>
    <SEO title="404: Not found" />
    <Section>
      <Typography variant="h4" component="h4">
        404 - Seite nicht gefunden
      </Typography>
    </Section>
  </MainLayout>
)

export default NotFoundPage
